import React from 'react'
import { graphql, Link } from 'gatsby'

const NavLink = ({ to, children}) => {
  if (!to) return null;

  return <Link to={to.node.frontmatter.path}>{children}</Link>
}

const Template = ({data, pageContext}) => {
  console.log(pageContext)
  const { next, prev } = pageContext
  const { frontmatter: {title}, html } = data.markdownRemark
  return (
    <div>
      <h1>
        {title}
      </h1>
      <div
        dangerouslySetInnerHTML={{__html: html}}
      />
      <NavLink to={next}>Next</NavLink>
      <NavLink to={prev}>Previous</NavLink>
    </div>
  )
}

export const query = graphql`
 query($pathSlug: String!) {
   markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
     html
     frontmatter {
       title
     }
   }
 }
`
export default Template